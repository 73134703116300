<template>
  <div class="content">
    <sub-title>미니게임 베팅내역</sub-title>
    <bet-history-links></bet-history-links>
    <div class="minigame_bet_history">
      <leisure-bet-history-detail :bet-list="betList"
                                  @deleteBet="deleteBet"
                                  @cancelBet="cancelBet"
                                  @cancelOrDelBet="cancelOrDelBet"
                                  :position="1"
                                  @betInfoChecked="betInfoChecked"></leisure-bet-history-detail>

      <!--                    배팅내역에서 게시글작성-->
      <!--                        <board-writer-->
      <!--                                :bet-ids="selectedBetIds"-->
      <!--                                :from="1"-->
      <!--                                :btype="sportsConst.GAME_LEISURE" btn-text="게시판내역 등록"></board-writer>-->

    </div>


    <pagination :page-index=" pageNum"
                :total="total"
                :page-size="pageSize"
                @change="pageChange"></pagination>
  </div>

</template>


<script>

import SubTitle from "@/views/afront/zero/SubTitle";
import leisureConst from "@/common/leisureConst";
import sportsConst from "@/common/sportsConst";
import {deleteById, getLeisureBetList, cancelById} from "@/network/leisureRequest";
import Pagination from "@/components/pagenation/Pagination";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";
import BoardWriter from "@/components/BoardWriter";
import {postionMixin} from "@/common/mixin";
import BetHistoryLinks from "@/views/afront/sports/match/BetHistoryLinks.vue";
import LeisureBetHistoryDetail from "@/views/afront/leisure/LeisureBetHistoryDetail.vue";

export default {
  name: "LeisureBetHistory",
  mixins: [postionMixin],
  components: {
    LeisureBetHistoryDetail,
    BetHistoryLinks,
    BoardWriter, Pagination, SubTitle,
  },
  data() {
    return {
      leisureConst,
      sportsConst,
      pageNum: 1,
      pageSize: 10,
      orderBy: '',
      search:{},
      total: 1,
      betList: [],
      selectedBetIds: [],
      position: "M베팅내역",
    }
  },
  methods: {

    /*SportsBetListComp.vue에서 체크박스 클릭시*/
    betInfoChecked(betIds) {
      this.selectedBetIds = betIds
    },

    /*SportsBetListComp.vue에서 취소 또는 삭제시 베팅내역 새로고침*/
    cancelOrDelBet() {
      this.getLeisureBetList()
    },
    getLeisureBetList() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getLeisureBetList(this.search, this.pageNum, this.pageSize, this.orderBy).then(res => {
        if (res.data.success) {
          this.betList = res.data.data
          this.total = res.data.total
          //로또일경우
          this.betList.forEach((game) => {
            if (game.attribute === leisureConst.LEISURE_ATTRIBUTE_LOTTO_POWERBALL) {
              try {
                game.selectedValue = JSON.parse(game.selectedValue);
                game.selectedValue = game.selectedValue.ball1 + ", " + game.selectedValue.ball2 + ", "
                    + game.selectedValue.ball3 + ", " + game.selectedValue.ball4 + ", " + game.selectedValue.ball5 + " : "
                    + game.selectedValue.powerball;
                if (game.selectedValueText != null) {
                  game.selectedValueText = JSON.parse(game.selectedValueText);
                  game.selectedValueText = game.selectedValueText.res;
                }
              } catch (e) {

              }
            }
          })
          this.$store.commit(RECEIVE_HIDE_LOADING)
        }
      })
    },
    cancelBet(id) {
      cancelById(id).then(res => {
        if (res.data.success) {
          this.getLeisureBetList()
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    deleteBet(id) {
      deleteById(id).then(res => {
        if (res.data.success) {
          this.getLeisureBetList()
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    changeBetResult(br) {
      this.search.betResult = br
    },
    pageChange(page) {
      this.pageNum = page
      this.getLeisureBetList()
    }
  },
  created() {
    this.getLeisureBetList()
    this.$store.state.currentBetHistoryIndex = 2
  },
  watch: {

  }
}
</script>

<style scoped>
@import url("../../../assets/css/front/afrontminigame.css");


</style>