<template>
  <div style="width: 100%">
    <table class="utable" v-for="(item,idx) in betList">
      <tr>
        <th style="width: 33%">게임</th>
        <th style="width: 33%">회차</th>
        <th>베팅시간</th>
      </tr>
      <tr>
        <td>{{ item.kindConfig.kindName }}</td>
        <td>{{ item.leisureGame.sequence }}</td>
        <td>{{ item.createTime|datef('MM-DD HH:mm') }}</td>
      </tr>
      <tr>
        <th>배당</th>
        <th>베팅금</th>
        <th>예상적중금</th>
      </tr>
      <tr>
        <td class="text-white">{{ item.betOdds }}</td>
        <td class="text-white">{{ item.betCash|comma }}원</td>
        <td class="text-white">{{ item.totalCash|comma }}원</td>
      </tr>
      <tr>
        <th>종목</th>
        <th>선택</th>
        <th>결과</th>
      </tr>
      <tr class="">
        <td>
          <input v-if="position" type="checkbox"
                 @click="checkBetId(item.id)">
          <span>{{ item.attributeConfig.attrName }}</span>
        </td>
        <td>
          <p style="font-weight: bold">{{ item.selectedValueText }}</p>
          <p style="font-weight: bold" v-if="item.leisureGame.homeTeamName != null">
            {{ item.leisureGame.homeTeamName + ' vs ' + item.leisureGame.awayTeamName }}</p>
        </td>
        <td>
          <span v-if="item.betResult === sportsConst.BET_RESULT_WIN" class="text_win">당첨</span>
          <span v-if="item.betResult === sportsConst.BET_RESULT_LOSE" class="text_lose">낙첨</span>
          <span v-if="item.betResult === sportsConst.BET_RESULT_SPECIALCASE" class="text_special">적특</span>
          <span v-if="item.betResult === sportsConst.BET_RESULT_CANCEL" class="text_cancel">취소</span>
          <span v-if="item.betResult === sportsConst.BET_RESULT_WAITING" class="text_ing">대기중</span>
        </td>
      </tr>
      <!--            <tr class="">-->
      <!--              -->
      <!--                <td class="btns">-->
      <!--                    <button class="btn01 text-red" v-if="item.betResult === sportsConst.BET_RESULT_WAITING && position"-->
      <!--                            @click="cancelBet(item.id)"> <i class="fa fa-close"> 취소</i>-->
      <!--                    </button>-->
      <!--                    <button class="badge badge-red" v-if="item.betResult !== sportsConst.BET_RESULT_WAITING && position"-->
      <!--                            @click="deleteBet(item.id)"><i class="fa fa-close"> 삭제</i>-->
      <!--                    </button>-->
      <!--                    <router-link href="javascript:void(0)" tag="a"  v-if="position"-->
      <!--                                 :to="{path: '/customer_center', query: {lbid:item.id,t: new Date().getTime()}}"-->
      <!--                                 class="badge badge-success ml-2">  <i class="fa fa-question-circle-o"> 문의하기</i></router-link>-->
      <!--                </td>-->
      <!--            </tr>-->
    </table>
    <div class="history_btns">
      <button class="ask" @click="add2customercenter"
              v-if="position">
        선택문의
      </button>
      <button class="seldel"
              @click="deleteBet" v-if="position">
        선택삭제
      </button>
      <button class="delall" @click="deleteAllLeisureBetInfo"
              v-if="position">
        전체삭제
      </button>
    </div>

  </div>
</template>

<script>
import sportsConst from "@/common/sportsConst";
import leisureConst from "@/common/leisureConst";
import {deleteAllLeisureBet, deleteByIds} from "@/network/leisureRequest";

export default {
  name: "LeisureBetHistoryDetail",
  props: {
    betList: {
      type: Array,
      default() {
        return []
      }
    },
    position: {
      type: Number,
      default: 1 // 0:게시글에등록된 베팅내역(삭제,취소,선택체크 를 숨김) 1: 베팅내역 리스트
    }
  },
  data() {
    return {
      sportsConst,
      leisureConst,
      betIds: [],
    }
  },
  methods: {
    askandaddbetids() {
      this.$swal({
        title: '',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          deleteAllLeisureBet().then(res => {
            if (res.data.success) {
              this.$emit('cancelOrDelBet')
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    deleteAllLeisureBetInfo() {
      this.$swal({
        title: '전체 베팅내역을 삭제하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          deleteAllLeisureBet().then(res => {
            if (res.data.success) {
              this.$emit('cancelOrDelBet')
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    checkBetId(id) {
      let index = this.betIds.findIndex((item) => {
        return item === id
      });
      if (index === -1) {
        this.betIds.push(id)
      } else {
        this.betIds.splice(index, 1)
      }
      this.$emit('betInfoChecked', this.betIds)
    },
    deleteBet() {
      if (this.betIds.length == 0) {
        this.$swal({
          title: '베팅내역을 선택해주세요',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      this.$swal({
        title: '선택하신 베팅내역을 삭제하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        this.betInfo = {selectedVals: []}
        if (res.value) {
          let ids = this.betIds.join(',');
          deleteByIds(ids).then(res => {
            this.$emit('cancelOrDelBet')
          })
        }
      })
    },
    cancelBet(id) {
      this.$swal({
        title: '해당 베팅내역을 취소하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        this.betInfo = {selectedVals: []}
        if (res.value) {
          this.$emit('cancelBet', id)
        }
      })
    },
    add2customercenter() {
      //:to=""
      if (this.betIds.length == 0) {
        this.$swal({
          title: '베팅내역을 선택해주세요',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      this.$router.push({
        path: '/customer_center',
        query: {lbid: this.betIds.join(","), t: new Date().getTime()}
      })
    },
  }
}
</script>

<style scoped>
.utable {
  border: .1rem solid #888888;
  margin-bottom: 1rem;
}

.utable td {
  border-bottom: transparent;
}

</style>